const RedirectManufacturerCode = () => {
  return null
}

export const getServerData = async ({
  params: { manufacturerCode, storeId },
}: {
  params: Record<string, string>
}) => {
  const CACHE_CONTROL = `max-age=300, s-maxage=7200, stale-while-revalidate`

  const redirectUrl = `/pesquisa?q=${manufacturerCode}${
    storeId ? `&storeId=${storeId}` : ''
  }`

  if (manufacturerCode) {
    return {
      status: 301,
      props: {},
      headers: {
        'cache-control': CACHE_CONTROL,
        location: redirectUrl,
      },
    }
  }

  return {
    status: 404,
    headers: {},
    props: {},
  }
}

export default RedirectManufacturerCode
